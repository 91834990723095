import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'
import Layout from "../components/Layout"
import { GatsbySeo, FAQJsonLd } from 'gatsby-plugin-next-seo';

class Etusivu extends React.Component {
  render() {
  const page = get(this, 'props.data.page')
  const { databaseId } = page

  return (
    <Layout
      bodyClass={`page-template-default page page-id-${databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <GatsbySeo 
        title={page.seo.title} description={page.seo.metaDesc} 
        canonical={`https://www.pelisivustot.fi/`}
      />
      <FAQJsonLd
        questions={[
          { question: 'Ovat netissä toimivat pelisivustot laillisia?', answer: 'Kyllä ovat. Suomessa mikään taho ei voi kieltää netissä toimivilla pelisivuilla viihtymistä, vaikka Veikkauksella oma monopoliasemansa valtiomme sisällä onkin. Netin piirissä tuo monopoli ei kuitenkaan päde, ja täten sinivalkoiset pelurit voivat täysin vapaasti valita oman pelipaikkansa Internetin ihmeellisestä maailmasta.' },
          { question: 'Mistä tunnistan luotettavat ja turvalliset pelisivut?', answer: 'Suosittelemme tarkkailemaan pelisivustoa valitessa etenkin pelipaikan omistamaa lupalisenssiä, sillä se kertoo paljon casinon toiminnasta ja taustasta ylipäätään. Esimerkiksi Viron tai Maltan lisenssillä varustetut sivustot ovat aivan kärkitasoa, mitä yleiseen luotettavuuteen ja turvallisuuteen tulee.' },
          { question: 'Ovatko pelisivustot aina verovapaita voittojen suhteen?', answer: 'Eivät ole, vaan tämäkin seikka ratkeaa pelisivun lisenssin perusteella. Mikäli pelipaikan lisenssi on ETA-alueelta, on kyseessä verovapaa pelisivu. Toisin sanoen siis luotettavat ja turvalliset pelipaikat ovat myös verovapaita casinoita, sillä Viro ja Malta kuuluvat tunnetusti tähän ETA-alueeseen!' },
          { question: 'Millaisia ovat parhaat bonukset, joita pelisivustoilta voi saada?', answer: 'Sivustomme toimitustiimin mielestä parhaimmat bonukset saadaan niin sanotusti ilman talletusta, jolloin pääset pelaamaan pelisivustolle täysin ilmaiseksi. Hyviä etuja ovat myös sellaiset bonukset, joissa pelaaja saa pienellä talletuksella joko ison määrän bonusrahaa tai runsaasti ilmaiskierroksia.' },
          { question: 'Mitä rahapelejä netin pelisivuilta voi löytää?', answer: 'Netissä toimivat pelisivustot tarjoilevat pelureille kasinoviihdettä hyvinkin laajalti, joskin valtaosa pelivalikoimista keskittyvät useimmiten kolikkopeleihin – eli slotteihin – sekä live-pöytäpeleihin. Myös esimerkiksi nettibingo, nettipokeri sekä lotto ja vedonlyönti voivat myös olla pelipaikkojen viihdetarjonnassa mukana.' },
        ]}
      />
      <article
        className={`frontpage page type-page status-publish hentry`}
      > 
        <header className="entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1
              className="entry-title"
              dangerouslySetInnerHTML={{ __html: page.title }}
            />
          </div>
        </header>
        <div className="post-inner thin">
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </div>
      </article>
    </Layout>
  )
  }
}
export default Etusivu

export const query = graphql`
query FrontPage {
    page: wpPage(title: {eq: "Parhaat pelisivustot"}) {
      id
      title
      content
      databaseId
      seo {
        metaDesc
        title
      }
      featuredImage {
        node {
          altText
          mediaItemUrl
          slug
        }
      }
    }
  }
`
